<template>
  <div class="kczx">
    <!-- logo -->
    <div class="kczx-logo">
      <!-- <img :src="`${$baseUrl}/kczx/banner.png`" /> -->
      <img :src="`${$baseUrl}/kczx/banner.jpg`" /><!-- 20240814付小荣要求修改 -->
    </div>

    <!-- 课程特点 -->
    <div class="kczx-td">
      <div class="td">
        <img src="~@/assets/page/kczx/icon/Slice 6.png" />
        <div class="title">知识点全面</div>

        <div class="detail">600+核心个技能点<br />适合0基础学习</div>
      </div>
      <div class="td">
        <img src="~@/assets/page/kczx/icon/Slice 5.png" />
        <div class="title">企业级项目导向</div>

        <div class="detail">40+个企业级项目<br />主流业务全覆盖</div>
      </div>
      <div class="td">
        <img src="~@/assets/page/kczx/icon/Slice 7.png" />
        <div class="title mq">名企架构师<br />手把手教学</div>
      </div>
      <div class="td">
        <img src="~@/assets/page/kczx/icon/Slice 8.png" />
        <div class="title">全程实操拔高</div>

        <div class="detail">80000+行优质代码<br />巩固编程硬实力</div>
      </div>
      <div class="td">
        <img src="~@/assets/page/kczx/icon/Slice 9.png" />
        <div class="title">立体式全方位服务</div>
        <div class="detail">小白入学到高薪就业<br />问题一站式解决</div>
      </div>
    </div>
    <!-- 课程体系背景 - 开始 -->
    <div style="background-color:#F7F8FD;padding-bottom:60px;">

      <div class="title-kctx" style="padding-bottom: 36px;">
        <div class="index-title">
          <span class="title_cn_left">文都智链——JAVA全栈标准化课程体系（全日班）</span><span class="title_cn_right">课程大纲</span>
          <div class="title_en">COURSE OUTLINE</div>
        </div>
      </div>

      <!-- 课程大纲 -->
      <div class="kczx-outline">

        <div class="tabs">
          <div class="left_tab" :class="getClass(idx)" v-for="(course,idx) in leftCourseList" :key="idx"
            @mouseenter="mouseenterEvent(idx)">
            <div class="stage">{{course.stage}}</div>
            <div class="headline">{{course.name}}</div>
          </div>
        </div>

        <div class="content-container">
          <div class="content">

            <div class="title">{{ courseList[courseOption.curCourseIndex] &&
            courseList[courseOption.curCourseIndex].stage }}：{{ courseList[courseOption.curCourseIndex] &&
              courseList[courseOption.curCourseIndex].name }}</div>
            <h2>课程内容</h2>
            <div class="detail">{{ courseList[courseOption.curCourseIndex] &&
            courseList[courseOption.curCourseIndex].content }}</div>
            <div class="dash_line"></div>
            <h2>学习目标</h2>
            <div class="detail">{{ courseList[courseOption.curCourseIndex] &&
            courseList[courseOption.curCourseIndex].target }}</div>
            <div class="dash_line"></div>
            <h2 class="ckys">课程优势</h2>
            <div class="detail">{{ courseList[courseOption.curCourseIndex] &&
            courseList[courseOption.curCourseIndex].position }}</div>
          </div>
        </div>

        <div class="tabs tabs_right">
          <div class="right_tab" :class="getClass(idx+6)" v-for="(course, idx) in rightCourseList" :key="idx"
            @mouseenter="mouseenterEvent(idx+6)">
            <div class="stage">{{ course.stage }}</div>
            <div class="headline">{{ course.name }}</div>
          </div>
        </div>
      </div>

    </div>
    <!-- 课程体系背景 - 结束 -->

    <div class="title-kctx">
      <div class="index-title">
        <span class="title_cn_left">企业级</span><span class="title_cn_right">项目实战</span>
        <div class="title_en">PROJECT PRACTICE</div>
      </div>
    </div>
    <!-- 项目展示 -->
    <div class="kczx-show">



      <div class="main">
        <div class="large">
          <img v-if="projectList.length>0"
            :src="`${$apiBaseUrl}${projectList[imgOption.curProjectIndex] && projectList[imgOption.curProjectIndex].imageUrl}`"
           />
        </div>

        <div class="introduce">

          <div class="introduce-title">
            <img src="~@/assets/page/kczx/icon/Slice 16@2x.png">
            <div class="top">{{ projectList[imgOption.curProjectIndex] && projectList[imgOption.curProjectIndex].name }}
            </div>
            <img src="~@/assets/page/kczx/icon/Slice 17@2x.png">
          </div>

          <div class="detail">{{ projectList[imgOption.curProjectIndex] &&
          projectList[imgOption.curProjectIndex].content }}</div>
        </div>

      </div>
      <div class="img-visible">
        <!-- 
        <img class="prev" src="~@/assets/page/kczx/sec10_prev.png" @click="prevPage" />
        <img class="next" src="~@/assets/page/kczx/sec10_next.png" @click="nextPage" /> 
        -->

        <div class="img-scroll">
          <div class="img-container" :style="containerStyle">
            <div v-for="(project, idx) in projectList" :key="idx">
              <div class="img" @mouseenter="mouseenterImg(idx)" @mouseleave="mouseleaveImg"
                :style="{border: `4px solid ${idx == imgOption.curProjectIndex?'#1C70FF':'#E7EDF8'}`}">
                <img :src="`${$apiBaseUrl}${project.imageUrl}`" />
                <!-- <div :class="getCover(idx)"></div> -->
              </div>
              <div style="margin-top:13px;font-size: 16px;
                          font-family: PingFang SC-Regular, PingFang SC;
                          font-weight: 400; color: #333333; line-height: 19px;">{{project.name}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

// 课程参数
const courseOption = {
  curCourseIndex: 0,
}
const courseList = []; // 课程列表
// 左边课程列表
const leftCourseList = function () {
  return this.courseList.filter((item, idx) => idx < this.courseList.length / 2);
};
// 右边课程列表
const rightCourseList = function () {
  return this.courseList.filter((item, idx) => idx >= this.courseList.length / 2);
};
const getCourseList = async function () {
  const result = await this.$httpGet("/course/list");
  this.courseList = result.data;
};
// 获取鼠标移入样式
const getClass = function (idx) {
  if (idx == courseOption.curCourseIndex) return idx < 6 ? 'left_active' : 'right_active';
  return '';
}
// 鼠标移入事件
const mouseenterEvent = function (idx) {
  courseOption.curCourseIndex = idx;
}

// 项目展示

const pageImageCount = 6;//每页图片数量

const projectList = [];
// 获取项目列表
const getProjectList = async function () {
  const result = await this.$httpGet("/project/list");
  this.projectList = result.data;

  // 图片容器宽度
  this.imgOption.containerWidth = Math.ceil(this.projectList.length / pageImageCount) * 100 + "%";
  console.log(Math.ceil(this.projectList.length / pageImageCount) * 100 + "%");
  this.containerStyle.width = this.imgOption.containerWidth;
}
const imgOption = {
  curProjectIndex: 0, // 当前图片索引
  timer: '', // 定时器
  containerWidth: 0 // 图片容器宽度
}
// 底层图片容器的样式
const containerStyle = {
  width: '',
  position: 'relative',
  left: 0
}
// 切换下一张图片
const changeNext = function () {
  imgOption.curProjectIndex++;

  // 一页显示pageImageCount张图片，超过pageImageCount张跳到下一页
  if (imgOption.curProjectIndex % pageImageCount == 0) {
    containerStyle.left = '-100%';
  }
  // 如果没有下一张图片，自动切换到第一张
  if (imgOption.curProjectIndex >= this.projectList.length) {
    imgOption.curProjectIndex = 0;
    containerStyle.left = 0;
  }
}
const mouseenterImg = function (idx) {
  imgOption.curProjectIndex = idx;
  clearInterval(imgOption.timer)
}
const mouseleaveImg = function () {
  imgOption.timer = setInterval(this.changeNext, 3000)
}
const getCover = function (idx) {
  if (idx == imgOption.curProjectIndex) {
    return '';
  }
  return 'cover'
}
// 上一页
const prevPage = function () {
  let num = parseInt(containerStyle.left);
  if (num < 0) {
    containerStyle.left = (num + 100) + '%';
  }
}
// 下一页
const nextPage = function () {
  let num = parseInt(containerStyle.left);
  let cw = parseInt(imgOption.containerWidth);//容器宽度
  if (num - 100 > -cw) {
    containerStyle.left = (num - 100) + '%';
  }
}
export default {
  data() {
    return {
      courseOption,
      courseList,
      projectList,
      imgOption,
      containerStyle
    }
  },
  created() {
    // 获取课程列表
    this.getCourseList();
    // 默认当前课程为第一个
    this.courseOption.curCourseIndex = 0;
    // 获取项目列表
    this.getProjectList();
    // 默认当前图片为第一张
    this.imgOption.curProjectIndex = 0;
    imgOption.timer = setInterval(this.changeNext, 3000)
  },
  computed: {
    leftCourseList,
    rightCourseList
  },
  methods: {
    getCourseList,
    mouseenterEvent,
    getClass,
    changeNext,
    mouseenterImg,
    mouseleaveImg,
    getCover,
    prevPage,
    nextPage,
    getProjectList
  }
}
</script>

<style scoped>
@import url('./kczx.css');
</style>