import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter);
import routes from './routes';
import {
	BASE_URL
} from '../constants'


const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return routerPush.call(this, location).catch(error => error)
}

const router = new VueRouter({
	mode: 'history',
	routes,
	base: BASE_URL,
	scrollBehavior(to, from, savedPosition) {
		return { x: 0, y: 0 }
	}
})


//白名单

router.beforeEach((to, from, next) => {
	console.log('前置守卫！');
	//存储当前路由
	sessionStorage.setItem('currentRouteName', to.name || '');
	next();
})

export default router;