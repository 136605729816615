import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api' //引入组合式API插件


import 'font-awesome/css/font-awesome.min.css'

import constants from '@/common/constants'
import router from '@/common/router'
import store from "@/common/store"

//导入已封装好的服务端访问对象
import request from "@/common/request"

//数据字典初始化
//import ddo from "@/common/ddo"

//导入快捷提示对象
import tip from "@/common/tip"

//导入防抖函数
import debounce from "@/common/debounce"

//加载提示组件初始化
//import "@/common/sureLoading/loading"

//导入加载提示
import loading from "@/common/sureLoading"

import App from './App.vue'

import VideoPlayer from 'vue-video-player'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'




Vue.use(VueCompositionAPI);//引入组合式API插件
Vue.use(constants);
Vue.use(request);
Vue.use(tip);
Vue.use(debounce);
Vue.use(loading);
Vue.use(VideoPlayer);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
