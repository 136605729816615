<template>
  <!-- 就业服务贯穿全程 -->


  <div class="jybz-gc">

    <div class="jybz-gc-nav">
      <div
          class="jybz-gc-nav-item"
          @mouseover="currentGcIdx = gcIdx"
          :style="{           
            background: gcIdx == currentGcIdx?'#FAFCFF':'transparent',            
            border: gcIdx == currentGcIdx?'1px solid #D7E7FF':'1px solid transparent'
          }"
          v-for="(gc, gcIdx) in gcList"
          :key="gcIdx"
      >
        <img v-if="gcIdx==0"  src="~@/assets/page/jybz/icon/Slice 37@2x.png">
        <img v-else-if="gcIdx==1"  src="~@/assets/page/jybz/icon/Slice 38@2x.png">
        <img v-else-if="gcIdx==2"  src="~@/assets/page/jybz/icon/Slice 39@2x.png">
        <img v-else-if="gcIdx==3"  src="~@/assets/page/jybz/icon/Slice 40@2x.png">
        <img v-else-if="gcIdx==4"  src="~@/assets/page/jybz/icon/Slice 41@2x.png">
        <h3 class="title"
            v-text="gc.title"
            :style="{ color: gcIdx == currentGcIdx ? '#1C70FF' : '#333' }" 
        ></h3>

        <span
            v-text="gc.subTitle"
        ></span>
<!--
  :style="{left:gcIdx == currentGcIdx?'0px':'50%',right:gcIdx == currentGcIdx?'0px':'50%'}"
-->
        <div class="active-line" :class="{active:gcIdx == currentGcIdx,noactive:gcIdx != currentGcIdx}" ></div>

      </div>
    </div>

    <div class="jybz-gc-content">
      <div class="jybz-gc-content-inner">
        <h2 v-text="gcList[currentGcIdx].title"></h2>
        <div class="jybz-gc-content-inner-desc" v-text="gcList[currentGcIdx].content"></div>
        <div class="jybz-gc-content-inner-imgs">
          <img
              v-for="(img,inx) in gcList[currentGcIdx].imgs"
              :key="img"
              :src="`${$baseUrl}${img}`"
          />
        </div>
      </div>

    
    </div>
  </div>

</template>

<script>
export default {
  data() {
    return {
      gcList,
      currentGcIdx:0,
      currentTabIdx: 0,
    };
  },
name: "XGcqc"

}
const gcList = [
  {
    title: "阶段测评",
    subTitle: "技术巩固，稳定提高",
    content: `在完成每个阶段的学习任务后，由任课讲师、就业班主任来考核学员的知识点掌握情况，查缺补漏，制定下一阶段的学习路线。`,
    imgs: ["/jybz/gc/jdcp/jdcp-1.jpg", "/jybz/gc/jdcp/jdcp-2.jpg"],
  },
  {
    title: "项目实操",
    subTitle: "项目答辩，分工合作",
    content: `分组进行项目实操，在实操结束后统一安排项目答辩，既了解企业级项目开发全流程，也提升学员的开发软实力。`,
    imgs: ["/jybz/gc/xmsc/xmsc-1.jpg", "/jybz/gc/xmsc/xmsc-2.jpg"],
  },
  {
    title: "就业指导",
    subTitle: "简历优化、模拟面试",
    content: `从招聘信息公布开始，就业班主任就会带领学员们从大厂招聘需求入手，撰写简历，优化细节。同时通过大厂模拟面试，提前感受大厂面试氛围，提升面试技巧，规划职业发展路线。`,
    imgs: ["/jybz/gc/jyzd/jyzd-1.jpg", "/jybz/gc/jyzd/jyzd-2.jpg"],
  },
  {
    title: "学员活动",
    subTitle: "定期举办学员团建活动",
    content: `通过室外趣味活动，既增进团队友谊，也让学生在忙碌的学习生活中得到放松，进而提升之后的学习效率。`,
    imgs: ["/jybz/gc/xyhd/xyhd-1.jpg", "/jybz/gc/xyhd/xyhd-2.jpg"],
  },
  {
    title: "名企游学",
    subTitle: "互联网大厂观摩学习",
    content: `定期组织学员到互联网大厂观摩学习，了解未来的真实工作内容与相关环境，进一步激发学习动力，为未来入职打好坚实基础。`,
    imgs: ["/jybz/gc/mqyx/mqyx-1.jpg", "/jybz/gc/mqyx/mqyx-2.jpg"],
  },
];
</script>

<style scoped>
@import url(./gcqc.css);
</style>