import { base_url } from "@/common"

//import index from '@/views/index/index.vue'
import about from '@/views/page/about/about.vue'
import kczx from '@/views/page/kczx/kczx.vue'
import jytd from '@/views/page/jytd/jytd.vue'
import jybz from '@/views/page/jybz/jybz.vue'
import xyfc from '@/views/page/xyfc/xyfc.vue'


const routes = [

    {
        path: '/ex',
        name: 'Ex',
        component: () => import('@/views/index/example.vue')
       
    },

    {
        path: '/',
        name: 'Index',
        component: () => import('@/views/index/index.vue'),
        //component:index,
        meta: {
            title: '首页',
            img: 'st-static/index-banner.png',
            detailsImg: 'st-static/index-banner-details.png'
        }
    },

    //关于我们
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        //component: () => import(/* webpackChunkName: "about" */ '@/views/page/about/about.vue')
        component:about,
    },
    //课程中心
    {
        path: '/kczx',
        name: 'Kczx',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        //component: () => import(/* webpackChunkName: "kczx" */ '@/views/page/kczx/kczx.vue')
        component:kczx
    },
    //教研团队
    {
        path: '/jytd',
        name: 'Jytd',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        //component: () => import(/* webpackChunkName: "jytd" */ '@/views/page/jytd/jytd.vue')
        component:jytd
    },
    //就业保障
    {
        path: '/jybz',
        name: 'Jybz',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        //component: () => import(/* webpackChunkName: "jytd" */ '@/views/page/jybz/jybz.vue')
        component:jybz
    },
    //学员风采
    {
        path: '/xyfc',
        name: 'Xyfc',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        //component: () => import(/* webpackChunkName: "xyfc" */ '@/views/page/xyfc/xyfc.vue')
        component:xyfc,
    },

];

export default routes;