import Vue from 'vue';
import TipApp from './TipApp.vue'

const Tip = Vue.extend(TipApp);

const tip_ = new Tip().$mount();
document.body.appendChild(tip_.$el);

export const tip = tip_;

export default {
	install(Vue) {
		Vue.prototype.$tip = tip_;
	}
};
