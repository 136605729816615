<template>
  <div class="xyfc">
    <!-- banner -->
    <div class="banner">
      <img :src="`${$baseUrl}/xyfc/banner.png`" />
    </div>

    <!--    <div class="title-kctx">-->
    <!--      <div class="index-title">-->
    <!--        <span class="title_cn_left">高薪经验分享</span><span class="title_cn_right"></span>-->
    <!--        <div class="title_en">HIGH SALARY EXPERIENCE SHARING</div>-->
    <!--      </div>-->
    <!--    </div>-->

    <!-- 学员分享视频 -->

    <div class="share_video">
      <div style="height: 50px"></div>
      <div class="title-jyfw1">
        <div class="index-title1">
          <span class="title_cn_left1">高薪经验分享</span><span class="title_cn_right"></span>
          <div class="title_en1">HIGH SALARY EXPERIENCE SHARING</div>
        </div>
        <div style="height: 50px"></div>
      </div>
      <div style="margin:0px auto;width:1400px; display: flex; justify-content: center;background-color: black;">
        <video src="~@/assets/page/xyfc/vedio.mp4"  height="788" style="flex:1;" controls autoplay loop></video>
      </div>



      <div class="imgxyfc-list">


        <a
          href="https://mp.weixin.qq.com/s?__biz=MzU2Njk2OTY1Mg==&mid=2247499359&idx=1&sn=286f525d5f696f5f8c478b1ce1a3d5c3&chksm=fca6ea04cbd16312869fbc81e7602b00c4527e611d2b6f936bf4d58bdfacb5d98b251cadd7db&token=684017645&lang=zh_CN#rd">
          <img class="imgxyfc" src="~@/assets/page/xyfc/第一张.png">
        </a>

        <a
          href="https://mp.weixin.qq.com/s?__biz=MzU2Njk2OTY1Mg==&mid=2247499296&idx=1&sn=8d2ea41efa55e49ce9698e93e55540e6&chksm=fca6ea7bcbd1636ddcd3fe52774611ceba48ec7c977955ab79217946e384d9f855398442da98&token=684017645&lang=zh_CN#rd">
          <img class="imgxyfc" src="~@/assets/page/xyfc/第二张.png">
        </a>

        <a
          href="https://mp.weixin.qq.com/s?__biz=MzU2Njk2OTY1Mg==&mid=2247488070&idx=1&sn=ad3520fdc37e3e1a4ce33c216ba9a902&chksm=fca5061dcbd28f0bd61f2483a7aeb5c17ba6741aebfb504291b17d67606bfbcbb70c9af95787&token=684017645&lang=zh_CN#rd">
          <img class="imgxyfc" src="~@/assets/page/xyfc/第三张.png">
        </a>

        <a
          href="https://mp.weixin.qq.com/s?__biz=MzU2Njk2OTY1Mg==&mid=2247484571&idx=1&sn=6aabc40ccdc4e115053a1cd2dbb5dc28&chksm=fca510c0cbd299d6628670c7d80e8738b2349e9d233c27c037ed5f4e27fc7ba18aa97b3357ad&token=684017645&lang=zh_CN#rd">
          <img class="imgxyfc" src="~@/assets/page/xyfc/第四张.png">
        </a>


      </div>



    </div>




    <!-- 多种就业方式 -->
    <div class="work-container" style="background:transparent;padding-top:62px;">


      <div class="jybz-job1">

        <div class="title-jyfw1">
          <div class="index-title1">
            <span class="title_cn_left1">学习日常和学员团建</span><span class="title_cn_right"></span>
            <div class="title_en1">STUDY DAILY ABD LEAGUE BUILDING</div>
          </div>
        </div>


        <div class="x-arrow-tab1" style="height:auto;margin-bottom:25px;margin-top:36px;">

          <div v-for="(tab, idx) in tabList" :key="`a-${tab.value}`" class="x-arrow-tab-item1" v-text="tab.label"
            v-bind:style="{
            color:idx == ind?'#FFFFFF':'#000000',
            fontWeight:idx == ind?'bold':'normal',
            backgroundImage:
            idx == ind
            // 选中
            ? (idx==0 ? 'url(/xyfc/1.png)' : 'url(/xyfc/3.png)')
            // 未选中
            : (idx==1  ? 'url(/xyfc/2.png)' : 'url(/xyfc/4.png)'),               
            }" @mouseover="selectItem({ tab, idx })">
          </div>


        </div>

        <div class="jybz-job-content1">

         
            <div class="quyu" v-show="ind==0">
              <!--     第一个区域     -->
              <img class="img23" :src="`${$baseUrl}/xyfc/xxrc/Slice 23.png`" /><img class="img24"
                :src="`${$baseUrl}/xyfc/xxrc/Slice 24.png`" /><img class="img25"
                :src="`${$baseUrl}/xyfc/xxrc/Slice 25.png`" /><img class="img26"
                :src="`${$baseUrl}/xyfc/xxrc/Slice 26.png`" /><img class="img27"
                :src="`${$baseUrl}/xyfc/xxrc/Slice 27.png`" /><img class="img28"
                :src="`${$baseUrl}/xyfc/xxrc/Slice 28.png`" /><img class="img29"
                :src="`${$baseUrl}/xyfc/xxrc/Slice 29.png`" />
            </div>
          
            <div class="quyu" v-show="ind==1">
              <!--     第二个区域     -->
              <img class="img30" :src="`${$baseUrl}/xyfc/xxrc/Slice 30.png`" />
              <img class="img31" :src="`${$baseUrl}/xyfc/xxrc/Slice 31.png`" />
              <img class="img32" :src="`${$baseUrl}/xyfc/xxrc/Slice 32.png`" />
              <img class="img33" :src="`${$baseUrl}/xyfc/xxrc/Slice 33.png`" />
              <img class="img34" :src="`${$baseUrl}/xyfc/xxrc/Slice 34.png`" />
              <img class="img35" :src="`${$baseUrl}/xyfc/xxrc/Slice 35.png`" />
              <img class="img36" :src="`${$baseUrl}/xyfc/xxrc/Slice 36.png`" />
            </div>
          

        </div>


      </div>
    </div>





    <!-- 学习日常和学员团建 -->
    <!--    <div class="work-container">-->
    <!--      <div class="index-work">-->
    <!--        <div class="index-work-title">-->
    <!--          <div class="title_ch">学习日常和学员团建</div>-->
    <!--          <div class="title_en">STUDY DAILY ABD LEAGUE BUILDING</div>-->
    <!--        </div>-->
    <!--        <div style="height: 100px"></div>-->

    <!--        <div class="choseImg">-->

    <!--            <span v-for="(tab,idx) in tabList" v-text="tab.label" :key="currentGcIdx"></span>-->
    <!--          <br>-->
    <!--            <span>{{currentGcIdx}}</span>-->
    <!--            <img class="bg" v-if=""  src="~@/assets/page/xyfc/1.png">-->
    <!--        </div>-->


    <!--      </div>-->
    <!--    </div>-->


    <!-- 就业信息 -->
    <index-work></index-work>

  </div>


</template>

<script>
import IndexWork from '@/views/index/components/IndexWork.vue'

const ind = 0;


const tabList = [
  {
    value: 0,
    label: "学习日常",
    imgs: [
      "/jybz/job/tj/tj-1.jpg",
      "/jybz/job/tj/tj-xqhz-1.jpg",
      "/jybz/job/tj/tj-3.jpg",
      "/jybz/job/tj/tj-4.jpg",
    ],
  },
  {
    value: 1,
    label: "团 建",
    imgs: [
      "/jybz/job/sxh/sxh-1.jpg",
      "/jybz/job/sxh/sxh-xqhz-1.jpg",
      "/jybz/job/sxh/sxh-3.jpg",
      "/jybz/job/sxh/sxh-4.jpg",
    ],
  },

];



export default {
  methods: {

    selectItem({ tab, idx }) {
      this.ind = idx;
      //alert(this.ind)

    },
  },

  data() {
    return {
      tabList,
      ind,

    };
  },


  components: {
    IndexWork, // 就业信息
  }
}
</script>

<style scoped>
@import url('./xyfc.css');
</style>