<template>


  <!-- 就业保障 -->
  <div class="jybz-jybz">

    <div class="title-kctx">
      <div class="index-title">
        <span class="title_cn_left">就业保障</span><span class="title_cn_right"></span>
        <div class="title_en">EMPLOYMENT SECURITY</div>
      </div>
    </div>
    <div class="jybz-jybz-list">

      <div class="jybz-jybz-list-stage" v-for="jybz in jybzList" :key="jybz.stage">
        <div class="jybz-jybz-list-stage-name"
          :style="{backgroundImage:`url(${$baseUrl}${jybz.stageBgImg})`,backgroundSize:`100% ${jybz.stageBgImgHeight}`,height:`${jybz.height}`}">
          <span>{{jybz.stage}}</span>
        </div>
        <div class="jybz-jybz-list-stage-itemlist">
          <div class="jybz-jybz-list-stage-itemlist-item" v-for="(jybzItem,idx) in jybz.item"
            :key="jybz.stage+'-'+jybzItem.name"
            :style="{
              ...jybzItem.style,
              backgroundImage:`url(${$baseUrl}${jybzItem.bgImg})`,
              marginBottom:idx<jybz.item.length-2?`${jybz.itemMarginBottom}`:`0px`,
            }"
          >

            <div class="jybz-jybz-list-stage-itemlist-item-title" :style="{color:`${jybz.itemTitleColor}`}">
              {{jybzItem.name}}
            </div>
            <ul>
              <li v-for="content in jybzItem.contentList" :key="content" v-text="content"></li>                
            </ul>

          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
//就业保障清单
const jybzList = [
  {
    stage: "就业前期",
    height: '243px',
    itemTitleColor: "#1C70FF",
    stageBgImg: '/jybz/jiuyeqianqi_bg@2x.png',
    stageBgImgHeight: '208px',
    itemMarginBottom: '0px',
    item: [
      {
        name: "入学服务",
        bgImg: "/jybz/Slice1.png",
        contentList: [
          "建立学员档案，教辅资料发放，教辅系统开通",
          "MBTI职业性格测试，专业基础测试",
          "学员面谈，基本情况了解",
          "课程安排介绍，班级介绍，校区环境介绍",
          "职业生涯规划，就业目标初步确定",
        ],
      },
      {
        name: "学习规划",
        bgImg: "/jybz/Slice2.png",
        contentList: [
          "全程&阶段学习规划",
          "月度&每周&每日学习计划",
          "各阶段学习方法指导",
          "课程安排介绍，班级介绍，校区环境介绍",
          "各阶段&每月&每周学习重点提醒",
        ],
      },
    ],
  },
  //--------------------
  {
    stage: "就业中期",
    height: "729px",
    itemTitleColor: "#00D1EB",
    stageBgImg: '/jybz/jiuyezhongqi_bg@2x.png',
    stageBgImgHeight: '694px',
    itemMarginBottom: '9px',
    item: [
      {
        name: "督学服务",
        bgImg: "/jybz/Slice3.png",
        contentList: [
          "上课提醒，日常考勤，课堂纪律，晚自习",
          "课前预习，作业检查，笔记检查",
          "面试宝典抽查，视频补课，补弱练习",
        ],
      },
      {
        name: "测评&答疑",
        bgImg: "/jybz/Slice4.png",
        contentList: [
          "周测评&月测评&阶段测评&项目汇报",
          "随堂答疑，课后答疑，远程协助，课程回放",
          "作业点评与讲解，培优补弱专题课程",
        ],
      },
      {
        name: "学情评估",
        bgImg: "/jybz/Slice5.png",
        contentList: [
          "讲师课评，服务调研，反馈改进",
          "日常回访，月度面谈，阶段总结",
          "学习效果跟进与改善，安排互助对象",
        ],
      },
      {
        name: "状态管理",
        bgImg: "/jybz/Slice6.png",
        contentList: [
          "开课班会，固定班会，主题班会",
          "节日主题活动，生日会，阶段性主题活动",
          "全程学习压力疏导，日常&就业心态调整",
          "安全、健康、住宿、物品等生活帮助",
        ],
      },
      {
        name: "职业素养训练",
        bgImg: "/jybz/Slice7.png",
        contentList: [
          "就业经验分享，职场礼仪与职业形象等",
          "时间管理，情绪管理，目标管理，团队协作",
          "学习力，创新力，沟通演讲，思维认知等综合能力提升",
        ],
      },
      {
        name: "就业解读",
        bgImg: "/jybz/Slice8.png",
        contentList: [
          "就业形势分析，招聘流程解析",
          "目标企业&岗位解读",
          "企业开发全流程解析",
        ],
      },
    ],
  },
  //--------------------
  {
    stage: "就业冲刺",
    height: "729px",
    itemTitleColor: "#00C09B",
    stageBgImg: '/jybz/jiuyechongci_bg@2x.png',
    stageBgImgHeight: '694px',
    itemMarginBottom: '9px',
    item: [
      {
        name: "项目辅导",
        bgImg: "/jybz/Slice9.png",
        contentList: [
          "项目一对一辅导",
          "企业级项目开发思维升级",
          "多领域项目开发分享与项目阐述训练",
        ],
      },
      {
        name: "简历指导",
        bgImg: "/jybz/Slice10.png",
        style: { padding: '63px 0px 0px 58px' },
        contentList: ["简历制作、匹配与优化", "一对一简历指导"],
      },
      {
        name: "模拟面试",
        bgImg: "/jybz/Slice11.png",
        style: { padding: '63px 0px 0px 58px' },
        contentList: [
          "自我介绍，面试技巧与礼仪",
          "真实技术面&综合面情景演练",
          "面试复盘与优化",
        ],
      },
      {
        name: "就业推荐",
        bgImg: "/jybz/Slice12.png",
        style: { padding: '63px 0px 0px 58px' },
        contentList: [
          "名企内推",
          "合作企业双选会",
          "招聘信息筛选推送",
          "人才定制",
        ],
      },
      {
        name: "就业追踪",
        bgImg: "/jybz/Slice13.png",
        style: { padding: '63px 0px 0px 58px' },
        contentList: [
          "真实面试剖析与改进",
          "Offer筛选与匹配",
          "就业安全与入职建议",
        ],
      },
    ],
  },
  //--------------------
  {
    stage: "就业后期",
    height: "486px",
    itemTitleColor: "#490FA1",
    stageBgImg: '/jybz/jiuyehouqi_bg@2x.png',
    stageBgImgHeight: '451px',
    itemMarginBottom: '6px',
    item: [
      {
        name: "试用期陪伴",
        bgImg: "/jybz/Slice14.png",
        style: { padding: '63px 0px 0px 58px' },
        contentList: ["辅助顺利度过试用期"],
      },
      {
        name: "校友对接",
        bgImg: "/jybz/Slice15.png",
        style: { padding: '63px 0px 0px 58px' },
        contentList: ["建立联系，扩展社交圈，生活与工作相互帮助"],
      },
      {
        name: "大咖分享",
        bgImg: "/jybz/Slice16.png",
        style: { padding: '63px 0px 0px 58px' },
        contentList: ["行业大咖解读前沿技术"],
      },
      {
        name: "终身就业支持",
        bgImg: "/jybz/Slice17.png",
        style: { padding: '63px 0px 0px 58px' },
        contentList: ["免费重修、自主择课、技术支持、跳槽&升职攻略"],
      },
    ],
  },
];
export default {
  data() {
    return {
      jybzList,
    };
  },
};
</script>

<style scoped>
@import url('./XJybz.css');
</style>