import Vue from 'vue';

export const TITLE = process.env.VUE_APP_TITLE;
export const VERSION = process.env.VUE_APP_VERSION;
export const BASE_URL = process.env.BASE_URL.endsWith('/') ? process.env.BASE_URL.substring(0,process.env.BASE_URL.length-1) : process.env.BASE_URL;
export const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
export const LOCAL_TOKEN = process.env.VUE_APP_LOCAL_TOKEN;//本地存储TOKEN的名字
export const HEADER_TOKEN = process.env.VUE_APP_HEADER_TOKEN;//请求头上TOKEN的名字
export const LOGIN_ROUTE_NAME = process.env.VUE_APP_LOGIN_ROUTE_NAME;//登录路由名称

export default {
  install(app) {
    Vue.prototype.$appTitle = TITLE;
    Vue.prototype.$version = VERSION;
    Vue.prototype.$baseUrl = BASE_URL;
    Vue.prototype.$apiBaseUrl = API_BASE_URL;
  }
}