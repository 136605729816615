import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const state = {
	num: 100,

	nav: {
		top: '-200px',
		paddingTop: '0px'
	},

	//导航菜单
	menus: [
		{ id: 1, name: "首页", to: "Index" },
		{ id: 2, name: "课程中心", to: "Kczx" },
		{ id: 3, name: "教研团队", to: "Jytd" },
		{ id: 4, name: "就业保障", to: "Jybz" },
		{ id: 5, name: "学员风采", to: "Xyfc"},
		{ id: 6, name: "关于我们", to: "About"}		

	],
};

const getters = {
	myNum: state => state.num,
};

const mutations = {
	incrementNum(state) {
		state.num++;
	},
	//显示完整的普通导航菜单
	showFull(state) {
		state.nav.top = '0px';
		state.nav.paddingTop = '96px';
	},
	//显示一半普通导航菜单
	showHalf(state) {
		state.nav.top = '-140px';
		state.nav.paddingTop = '60px';
	},
	//隐藏普通导航菜单
	hide(state) {
		state.nav.top = '-200px';
		state.nav.paddingTop = '0px';
	},
};

const actions = {
	increatNum: ({ commit }) => window.setTimeout(() => commit('incrementNum'), 5000),
	//显示完整的普通导航菜单
	showFull({ commit }) {
		window.setTimeout(() => commit('showFull'), 0)
	},
	//显示一半普通导航菜单
	showHalf({ commit }) {
		window.setTimeout(() => commit('showHalf'), 0)
	},
	//隐藏普通导航菜单
	hide({ commit }) {
		window.setTimeout(() => commit('hide'), 0)
	},
};



export default new Vuex.Store({
	state,
	getters,
	mutations,
	actions,
	modules: {},
});
