<template>
  <div class="work-container">
    <div class="index-work">
      <!-- 标题 -->
      <div class="index-work-title">
        <div class="title_ch">就业信息</div>
        <div class="title_en">EMPLOYMENT INFORMATION</div>
      </div>
      <div class="index-work-list">
        <div class="index-work-list-header">
          <span style="width: 150px; flex: none; text-align: center">姓名</span>
          <span style="width: 300px; flex: none; text-align: center">专业</span>
          <span style="width: 380px; flex: none; text-align: center"
            >入职企业</span
          >
          <span style="width: 220px; flex: none; text-align: center"
            >工作岗位</span
          >
          <span style="width: 100px; flex: none; text-align: center">年薪</span>
          <span style="width: 150px; flex: none; text-align: center"
            >就业城市</span
          >
        </div>

        <!-- UI20220919要求修改 该div去掉下部外边距 -->
        <div
          style="
            margin: 8px auto 0px;
            height: 4px;
            border-radius: 2px;
            background-color: #1C70FF;
          "
        ></div>

        <vue-seamless-scroll
          :data="workList"
          :class-option="option"
          class="index-work-seamless"
        >
          <table>
            <tbody>
              <tr v-for="work in workList" :key="work.id" >
                <td style="width: 150px">{{ work.name }}</td>
                <td style="width: 300px">{{ work.major }}</td>
                <td style="width: 380px">{{ work.company }}</td>
                <td style="width: 220px">{{ work.job }}</td>
                <td style="width: 100px">{{ work.annualSalary }}万</td>
                <td style="width: 150px">{{ work.city }}</td>
              </tr>
            </tbody>
          </table>
        </vue-seamless-scroll>

      </div>
    </div>
  </div>
</template>
<script>
import VueSeamlessScroll from "vue-seamless-scroll";

const option = {
  step: 0.5, // 数值越大速度滚动越快
  limitMoveNum: 2, //newsList.length / 2 - 1, // 开始无缝滚动的数据量 this.dataList.length
  hoverStop: true, // 是否开启鼠标悬停stop
  direction: 1, // 0向下 1向上 2向左 3向右
  openWatch: false, // 开启数据实时监控刷新dom
  singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
  singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
  waitTime: 0, // 单步运动停止的时间(默认值1000ms)
};
const getWorkList = async function () {
  const res = await this.$httpGet('/front/work/list')
  //console.log(res.data)
  this.workList  = res.data
}
// const getWorkList = function () {
//   const arr = [];
//   for (let i = 0; i < 100; i++) {
//     arr.push({
//       id: i,
//       name: "姓名" + i,
//       edu: ["本科", "硕士"][Math.random() < 0.5 ? 0 : 1],
//       major: [
//         "计算机科学与技术",
//         "软件工程",
//         "网络工程",
//         "汉语言文学",
//         "精细化工",
//         "高分子化工",
//         "轻化工程",
//         "通信工程",
//       ][Math.floor(Math.random() * 8)],
//       company: "XXX软件开发有限公司",
//       position: ["Java工程师", "测试工程师", "前端工程师", "测试工程师"][
//         Math.floor(Math.random() * 4)
//       ],
//       salary: ["12W", "22W", "15W", "28W", "24W", "19W"][
//         Math.floor(Math.random() * 6)
//       ],
//       city: [
//         "西安",
//         "北京",
//         "成都",
//         "上海",
//         "深圳",
//         "广州",
//         "南京",
//         "郑州",
//         "重庆",
//       ][Math.floor(Math.random() * 9)],
//     });
//   }
//   this.workList = arr;
// };

export default {
  components:{
    VueSeamlessScroll
  },
  data() {
    return {
      workList: [],
      option,
    };
  },
  created() {
    this.getWorkList();
  },
  mounted() {},
  methods: {
    getWorkList,
    
  },
};
</script>

<style scoped >
@import url(./IndexWork.css);
</style>
