<template>
  <div class="x-arrow-tab">
<!--    <div class="x-arrow-tab-startend" :style="{ backgroundColor: color }"></div>-->

<!--    <div-->
<!--      class="x-arrow-tab-arrow"-->
<!--      :style="{-->
<!--        backgroundImage:-->
<!--          value == 0-->
<!--            ? `url(${$baseUrl}/jybz/job/img-1.png)`-->
<!--            : `url(${$baseUrl}/jybz/job/img-3.png)`,-->
<!--      }"-->
<!--    ></div>-->
    <div v-for="(tab, idx) in tabList" class="x-arrow-tab-item-package">




      <img class="imgicon" v-if="idx==0" src="~@/assets/page/jybz/icon/icon1.png" :style="{
          display:
          value==idx
          ? 'none':'block'
      }"/>
      <img class="imgicon" v-if="idx==1" src="~@/assets/page/jybz/icon/icon3.png" :style="{
          display:
          value==idx
          ? 'none':'block'
      }"/>
      <img class="imgicon" v-if="idx==2" src="~@/assets/page/jybz/icon/icon5.png" :style="{
           display:
          value==idx
          ? 'none':'block'
      }"/>
      <img class="imgicon" v-if="idx==3" src="~@/assets/page/jybz/icon/icon7.png" :style="{
          display:
          value==idx
          ? 'none':'block'
      }"/>

      <img class="imgicon" v-if="idx==0 && value==idx" src="~@/assets/page/jybz/icon/icon2.png"/>
      <img class="imgicon" v-if="idx==1 && value==idx" src="~@/assets/page/jybz/icon/icon4.png"/>
      <img class="imgicon" v-if="idx==2 && value==idx" src="~@/assets/page/jybz/icon/icon6.png"/>
      <img class="imgicon" v-if="idx==3 && value==idx" src="~@/assets/page/jybz/icon/icon8.png"/>



      <div
        :key="`a-${tab.value}`"
        class="x-arrow-tab-item"

        :style="{
          background:
            value == idx
              ? `linear-gradient(to right,${activeColorStart},${activeColorEnd})`
              : color,
          // backgroundImage:
          //  value == 0
          //     ? `url(~@/assets/page/jybz/icon/icon1.png)`
          //     : `url(~@/assets/page/jybz/icon/Slice 37@2x.png)`,
          color: value == idx ? activeTextColor : textColor,
        }"

        v-text="tab.label"
        @mouseover="selectItem({ tab, idx })"
      >
      </div>

    </div>

<!--    <div class="x-arrow-tab-startend" :style="{ backgroundColor: color }"></div>-->

    <!--
        #FB5F20 #FB7D4A

    -->
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object | String | Number,
      default: 0,
    },
    tabList: {
      type: Array,
      default: function () {
        return [];
      },
    },
    activeColorStart: {
      type: String,
      default: "#FB5F20",
    },
    activeColorEnd: {
      type: String,
    },
    color: {
      type: String,
    },
    textColor: {
      type: String,
      default: "#000000",
    },
    activeTextColor: {
      type: String,
      default: "#1C70FF",
    },
  },

  methods: {
    selectItem({ tab, idx }) {
      this.$emit("input", idx);
    },
  },
};
</script>

<style scoped>
@import url(./XArrowTab.css);
</style>