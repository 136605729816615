<script>
  import {mapState,mapActions } from 'vuex'
  import {  tip } from "@/common";

  let toManagerCount = 0;
  let timer = null;
  const clearManagerCount = ()=>{
    timer=setTimeout(()=>{
      toManagerCount = 0;
      clearManagerCount();
    },5000);
  };

  clearManagerCount();


  export default {
    inject: ["reload"], // 刷新当前路由
    data() {
      return {                           
       
                 
      };          
    },

    computed: {        
      ...mapState(['menus','nav']),      
      
    },

    created(){
      this.showFull();
    },

    methods: {
      ...mapActions(['showFull','showHalf','hide']),
      // 判断菜单是否选中                                                                     
      isActive(menu) {
        const currentRouteName = this.$route.name;
        //this.$route.name || sessionStorage.getItem("currentRouteName");

        return (
          currentRouteName &&
          (currentRouteName.indexOf(menu.to) === 0 ||
            (currentRouteName == "Details" &&
              this.$route.params.menuRouteName.indexOf(menu.to) === 0))
        );
      },

      // 在浏览器新的tab页打开超链
      linkToNewTab(linkUrl) {
        this.$refs.linkObj.href = linkUrl;
        this.$refs.linkObj.click();
      },

      onClickMenuItem(menu) {
        
        if (menu.to == "Index") {//如果为“首页”菜单，重置首页为第一屏
          //ddo.resetIndex();
          this.$router.replace({
            name: menu.to,
            // query: { _t: new Date().getTime() },
          });
        }
        else if (menu.to == this.$route.name || this.$route.name.indexOf(menu.to) == 0) {
          // 暂时注释 -- start       
           this.reload();
          // 暂时注释 -- end
        } else {
          // 暂时注释 -- start
          this.$router.push({
            name: menu.to,
          }); 
          // 暂时注释 -- end
        }
      },


      toManager(){
        toManagerCount++;        
        if(toManagerCount==3){
          timer && window.clearTimeout(timer);
          window.location.href='http://39.106.228.169:8091';
        }
      }

     
    },
  };
</script>
<template>
  <!-- 顶部导航条整体 -->
  <div class="st-nav2" :style="{ top: nav.top }">
    <!-- 下部导航菜单部分 -->
    <div class="nav">
      <div class="logo">
        <img :src="`${$baseUrl}/logo.png`" height="50" />
        <div class="manager" @click="toManager" ></div>
      </div>

      <div class="menus">
        <div class="menu-item" :class="{ active: isActive(menu) }" v-for="menu in menus" :key="menu.id">
          <div @click="onClickMenuItem(menu)" class="main-item">
            {{ menu.name }}
          </div>          
        </div>
      </div>
    </div>
    
    <!-- <a href="#" ref="linkObj" target="_blank" style="display: none"></a> -->
  </div>
</template>
<style scoped>
  @import url(./XNav.css);
</style>