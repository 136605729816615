<template>
  <div class="jybz">
    <!-- 就业服务 -->
    <div class="jybz-jyfw"><img 
      :src="`${$baseUrl}/jybz/Slice 66@2x.png`" 
      /></div>


    <div style="background-color:#F8F7FC;">
      <!-- 就业保障 -->
      <x-jybz></x-jybz>
    </div>


    <div class="title-jyfw">
      <div class="index-title">
        <span class="title_cn_left">就业服务</span><span class="title_cn_right">贯穿全程</span>
        <div class="title_en">EMPLOYMENT INFORMATION SERVICE</div>
      </div>
    </div>
    <div style="height: 50px"></div>
    <x-gcqc></x-gcqc>


    <div style="height: 50px"></div>

    <div class="jybz-dcjy-container">
      <!-- 多重就业模式 -->
      <div class="jybz-dcjy">
        <div class="jybz-dcjy-title">多重就业模式</div>
        <div class="jybz-dcjy-subtitle">MULTIPLE EMPLOYMENT  MODE</div>
        <x-arrow-tab :tabList="tabList" v-model="currentTabIdx" class="jybz-dcjy-tabs" ></x-arrow-tab>

        <div class="jybz-dcjy-content">
          <template v-if="tabList[currentTabIdx].label != '名企内推会'">
            <img
              v-for="img in tabList[currentTabIdx].imgs"
              :key="img"
              :src="`${$baseUrl}${img}`"
            />
          </template>
          <template v-if="tabList[currentTabIdx].label == '名企内推会'">
            <div>
              <img
                :src="`${$baseUrl}/jybz/job/nt/nt-1.png`"                
              />
              <div>
                <img :src="`${$baseUrl}/jybz/job/nt/sm.png`" />
                <ul>
                  <li>识别上图二维码</li>
                  <li>获得名企内推信息</li>
                  <li>更有诸多IT好礼等你来解锁</li>
                </ul>
                
              </div>
            </div>

            <div>
              <img
                :src="`${$baseUrl}/jybz/job/nt/nt-2.png`"
               
              />
              <div>
                <img :src="`${$baseUrl}/jybz/job/nt/sm.png`"/>
                <ul>
                  <li>识别上图二维码</li>
                  <li>了解最新校招咨询</li>
                  <li>更有免费好课等你来领取</li>
                </ul>
                
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>

    <div style="height: 50px"></div>

    <!-- 合作院校 -->
    <div class="jybz-hzyx" style="background-color: #FFFFFF;">

      <div class="jybz-hzyx-title">
        <div class="index-title">
          <span class="title_cn_left">合作院校</span><span class="title_cn_right"></span>
          <div class="title_en_1" 
              style="font-family: PingFang SC-Semibold, PingFang SC;
                     font-size:16px;line-height: 29px;font-weight: normal;
                     color:#999999;width:1215px;margin:0px auto;text-align: justify;text-align-last: center;letter-spacing: 2.3px;">文都教育持续深化校企合作，打造符合社会需
            求的高端技术人才，通过校内上课，优质实训
            等环节提供高质量的专业服务，为学生走好未
            来职业发展<br/>之路做好铺垫，实现学子高薪就业的梦想。
          </div>
        </div>
      </div>


      <div class="jybz-hzyx-content"></div>

      <div class="jybz-hzyx-imgs">

        <div class="jybz-hzyx-imgs-container">
            
            <div class="jybz-hzyx-imgs-container-imgs" :style="{left:imgsLeft+'px'}">

              <div v-for="xqhz in xqhzList" :key="xqhz" class="jybz-hzyx-imgs-container-imgs-item">
                <img :src="`${$baseUrl}${xqhz}`" style="border-radius: 10px;" />
              </div>

            </div>

        </div>

<!--        <div @click="toLeft" class="jybz-hzyx-imgs-opr-left" ><i class="fa fa-arrow-circle-o-left fa-5x"></i></div>-->
<!--        <div @click="toRight" class="jybz-hzyx-imgs-opr-right" ><i class="fa fa-arrow-circle-o-right fa-5x"></i></div>-->
        <div @click="toRight" class="jybz-hzyx-imgs-opr-left" ></div>
        <div @click="toLeft" class="jybz-hzyx-imgs-opr-right" ></div>


      </div>
    </div>

    <div style="height: 50px"></div>
  </div>
</template>

<script>
import XJybz from "@/components/XJybz.vue";
import XArrowTab from "@/components/XArrowTab.vue";
import XGcqc from "@/components/XGcqc.vue";


const gcList = [
  {
    title: "阶段测评",
    subTitle: "技术巩固，稳定提高",
    content: `在完成每个阶段的学习任务后，由任课讲师、就业班主任来考核学员的知识点掌握情况，查缺补漏，制定下一阶段的学习路线。`,
    imgs: ["/jybz/gc/jdcp/jdcp-1.jpg", "/jybz/gc/jdcp/jdcp-2.jpg"],
  },
  {
    title: "项目实操",
    subTitle: "项目答辩，分工合作",
    content: `分组进行项目实操，在实操结束后统一安排项目答辩，既了解企业级项目开发全流程，也提升学员的开发软实力。`,
    imgs: ["/jybz/gc/xmsc/xmsc-1.jpg", "/jybz/gc/xmsc/xmsc-2.jpg"],
  },
  {
    title: "就业指导",
    subTitle: "简历优化、模拟面试",
    content: `从招聘信息公布开始，就业班主任就会带领学员们从大厂招聘需求入手，撰写简历，优化细节。同时通过大厂模拟面试，提前感受大厂面试氛围，提升面试技巧，规划职业发展路线。`,
    imgs: ["/jybz/gc/jyzd/jyzd-1.jpg", "/jybz/gc/jyzd/jyzd-2.jpg"],
  },
  {
    title: "学员活动",
    subTitle: "定期举办学员团建活动",
    content: `通过室外趣味活动，既增进团队友谊，也让学生在忙碌的学习生活中得到放松，进而提升之后的学习效率。`,
    imgs: ["/jybz/gc/xyhd/xyhd-1.jpg", "/jybz/gc/xyhd/xyhd-2.jpg"],
  },
  {
    title: "名企游学",
    subTitle: "互联网大厂观摩学习",
    content: `定期组织学员到互联网大厂观摩学习，了解未来的真实工作内容与相关环境，进一步激发学习动力，为未来入职打好坚实基础。`,
    imgs: ["/jybz/gc/mqyx/mqyx-1.jpg", "/jybz/gc/mqyx/mqyx-2.jpg"],
  },
];

const tabList = [
  {
    value: 1,
    label: "就业推荐",
    imgs: [
      "/jybz/job/tj/tj-1.jpg",
      "/jybz/job/tj/tj-2.jpg",
      "/jybz/job/tj/tj-3.jpg",
      "/jybz/job/tj/tj-4.jpg",
    ],
  },
  {
    value: 2,
    label: "企业双选会",
    imgs: [
      "/jybz/job/sxh/sxh-1.jpg",
      "/jybz/job/sxh/sxh-2.jpg",
      "/jybz/job/sxh/sxh-3.jpg",
      "/jybz/job/sxh/sxh-4.jpg",
    ],
  },
  {
    value: 3,
    label: "专场招聘会",
    imgs: [
      "/jybz/job/zp/zp-1.jpg",
      "/jybz/job/zp/zp-2.jpg",
      "/jybz/job/zp/zp-3.jpg",
      "/jybz/job/zp/zp-4.jpg",
    ],
  },
  {
    value: 4,
    label: "名企内推会",
    imgs: ["/jybz/job/nt/nt-1.png", "/jybz/job/nt/nt-2.jpg"],
  },
];

const xqhzList = [
  "/jybz/xqhz/xqhz-1.jpg",
  "/jybz/xqhz/xqhz-2.jpg",
  "/jybz/xqhz/xqhz-3.jpg",
  "/jybz/xqhz/xqhz-4.jpg",
  "/jybz/xqhz/xqhz-5.jpg",
  "/jybz/xqhz/xqhz-6.jpg",
  "/jybz/xqhz/xqhz-7.jpg",
  "/jybz/xqhz/xqhz-8.jpg",
];

export default {
  components: {XGcqc, XJybz, XArrowTab },
  data() {
    return {
      //jybzList,
      gcList,
      currentGcIdx: 0,
      tabList,
      currentTabIdx: 0,
      xqhzList,
      
      
      imgsLeft:0
      
    };
  },
 computed:{
   imgsMinLeft(){
     if(this.xqhzList.length<4){
       return 0;
     }

     return -(this.xqhzList.length)*388.58 - (this.xqhzList.length-1)*18 + 3*388.58+18*2;
   }
 },
  methods:{
    toLeft(){
     
      if(this.imgsLeft <= this.imgsMinLeft){

        this.imgsLeft = this.imgsMinLeft;
        return;
      }

      const imgsLeft = this.imgsLeft  - 388.58-18;
      if(imgsLeft <= this.imgsMinLeft){
        this.imgsLeft = this.imgsMinLeft;
        return;
      }

      this.imgsLeft = imgsLeft;
      
    },
    toRight(){
     
      if(this.imgsLeft >= 0){

        this.imgsLeft = 0;
        return;
      }

      const imgsLeft = this.imgsLeft  + 388.58+18;
      if(imgsLeft >= 0){
        this.imgsLeft = 0;
        return;
      }

      this.imgsLeft = imgsLeft;

    }
  }
};
</script>

<style scoped>
@import url(./jybz.css);
</style>