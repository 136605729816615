<template>
  <div class="jytd">
    <div class="jytd-container">
      <div class="jytd-log">
        <img :src="`${$baseUrl}/jytd/Slice 8@2x.png`" />
      </div>


      <div class="title-kctx">
        <div class="index-title">
          <span class="title_cn_left">文都智链</span><span class="title_cn_right">师资力量</span>
          <div class="title_en">TEACHER RESOURCES</div>
        </div>
      </div>


      <div class="jytd-list">
        <div class="jytd-text">

          <p>
            文都集团大学教育教学研究院（简称“集团教研院”）是世纪文都集团，从事大学教育教研开发、教学管理，师资发展、师资培训与资源整合的特设机构。集团教研院下设西北分院、湖北分院、北京分院、河南分院等二十余家教研分院。目前，教研院在全国拥有教师团队600多人，其中85%毕业于985、211全国重点院校。
          </p>
          <p>
            为实现高新技术快速高质量的转化为教育产品，文都智链针对不同学科，与行业领军企业、行业协会、高校教育专家、企业技术总监等行业领军人物建立教学研究院，对专业建设、课程研发、师资培训和教学提供支持和指导，实现了产业技术到教育产品的转化和教育产品与院校实时的融合。
          </p>
        </div>
        <!--          张长鹏老师-->
        <div class="jytd-item" v-for="tea in teacherList" :key="tea.id">
          <img class="img1" src="~@/assets/page/jytd/Slice 9@2x.png">
          <div class="jytd-img">
            <img class="imgbg" src="~@/assets/page/jytd/Slice 15@2x.png">
            <img class="imgtea" :src="`${$apiBaseUrl}${tea.avatarJytd}`" />
          </div>
          <div class="jytd-info">
            <div class="jytd-info-name">
              <span :style="teacherName(0).style" v-text="tea.name" ></span>
              <span :style="teacherName(0).levelStyle" v-text="tea.level" ></span>
            </div>

            <div class="jytd-info-feature">
              <span>授课风格</span>
              <span v-text="tea.feature.join(',')" ></span>
            </div>
            <div class="jytd-info-intro">
              <span>讲师简介</span>
              <span v-text="tea.intro"></span>
            </div>
          </div>
        </div>

        <!--          马春森老师-->
        <!-- <div class="jytd-item">
          <img class="img1" src="~@/assets/page/jytd/Slice 9@2x.png">
          <div class="jytd-img">
            <img class="imgbg" src="~@/assets/page/jytd/Slice 15@2x.png">
            <img class="imgtea" src="~@/assets/page/jytd/teacher/Slice 55@2x.png" />
          </div>
          <div class="jytd-info">
            <div class="jytd-info-name">
              <span :style="teacherName(0).style">马春森</span>
              <span :style="teacherName(0).levelStyle">Java高级讲师</span>
            </div>

            <div class="jytd-info-feature">
              <span>授课风格</span>
              <span>举一反三,富有耐心,极度负责</span>
            </div>
            <div class="jytd-info-intro">
              <span>讲师简介</span>
              <span>精通Spring全家桶及持久层解决方案等企业主流开源框架，
                擅长Java、JavaWeb、Oracle、HTML5、VUE、ORM框架、SSM、
                SpringBoot等技术；获得 OSTA认证，曾参与数据接入平台、
                全程电子化办理系统、图像视频解析等项目研发；拥有丰富的一
                线开发和教学经验，深受学员喜爱。</span>
            </div>
          </div>
        </div> -->


        <!--          夏冬琦老师-->
        <!-- <div class="jytd-item">
          <img class="img1" src="~@/assets/page/jytd/Slice 9@2x.png">
          <div class="jytd-img">
            <img class="imgbg" src="~@/assets/page/jytd/Slice 15@2x.png">
            <img class="imgtea" src="~@/assets/page/jytd/teacher/Slice 56@2x.png" />
          </div>
          <div class="jytd-info">
            <div class="jytd-info-name">
              <span :style="teacherName(0).style">夏冬琦</span>
              <span :style="teacherName(0).levelStyle">Java高级讲师</span>
            </div>

            <div class="jytd-info-feature">
              <span>授课风格</span>
              <span>学富五车,情景式教学</span>
            </div>
            <div class="jytd-info-intro">
              <span>讲师简介</span>
              <span>曾就职于西北政法大学、IBM教育学院等企事业单位，
                先后担任过项目经理、系统架构师等职务；参与过电商、
                金融、医疗等领域的项目开发工作，擅长Java、JavaWeb、
                主流前端技术、服务端的高可用及性能提升，精通常见的开
                源框架，特别是对SpringCloud微服务、Vue3、前后端分
                离和项目实践具有丰富的教学经验和独到的见解；善于用
                通俗的案例讲解抽象的技术栈，注重学生实践动手能力和
                独立思考精神的培养。</span>
            </div>
          </div>
        </div> -->


        <!--          王含老师-->
        <!-- <div class="jytd-item">
          <img class="img1" src="~@/assets/page/jytd/Slice 9@2x.png">
          <div class="jytd-img">
            <img class="imgbg" src="~@/assets/page/jytd/Slice 15@2x.png">
            <img class="imgtea" src="~@/assets/page/jytd/teacher/Slice 57@2x.png" />
          </div>
          <div class="jytd-info">
            <div class="jytd-info-name">
              <span :style="teacherName(0).style">王含</span>
              <span :style="teacherName(0).levelStyle">C语言、Java高级讲师</span>
            </div>

            <div class="jytd-info-feature">
              <span>授课风格</span>
              <span>充满感染力,幽默严谨</span>
            </div>
            <div class="jytd-info-intro">
              <span>讲师简介</span>
              <span>大学生就业指导规划师，曾担任互联网公司创始人，
                参与过多种互联网系统平台的设计研发，拥有丰富的一线教学经验；
                擅长C语言、Java全栈、数据结构、大厂面试算法题解析；授课富
                有激情，能够化繁为简，案例式教学，被学员称为“用生命在教学”
                的好老师。</span>
            </div>
          </div>
        </div> -->


        <!--          张欢敏老师-->
        <!-- <div class="jytd-item">
          <img class="img1" src="~@/assets/page/jytd/Slice 9@2x.png">
          <div class="jytd-img">
            <img class="imgbg" src="~@/assets/page/jytd/Slice 15@2x.png">
            <img class="imgtea" src="~@/assets/page/jytd/teacher/Slice 60@2x.png" />
          </div>
          <div class="jytd-info">
            <div class="jytd-info-name">
              <span :style="teacherName(0).style">张欢敏</span>
              <span :style="teacherName(0).levelStyle">Java、Office高级讲师</span>
            </div>

            <div class="jytd-info-feature">
              <span>授课风格</span>
              <span>案例式教学,富有亲和力的小姐姐</span>
            </div>
            <div class="jytd-info-intro">
              <span>讲师简介</span>
              <span>精通企业主流开源框架，擅长JavaSE、
                JavaWeb、MySQL、Oracle、BootStrap、
                VUE、GIS、MyBatis、Hibernate、Spring、
                Spring MVC等技术；具有丰富的项目开发与教
                学经验，多次担任项目经理的职位，曾负责企业
                OA办公系统，物联网环保蓝天等知名项目研发。</span>
            </div>
          </div>
        </div> -->

        <!--          袁新老师-->
        <!--
        <div class="jytd-item">
          <img class="img1" src="~@/assets/page/jytd/Slice 9@2x.png">
          <div class="jytd-img">
            <img class="imgbg" src="~@/assets/page/jytd/Slice 15@2x.png">
            <img class="imgtea" src="~@/assets/page/jytd/teacher/Slice 61@2x.png" />
          </div>
          <div class="jytd-info">
            <div class="jytd-info-name">
              <span :style="teacherName(0).style">袁新</span>
              <span :style="teacherName(0).levelStyle">Java高级讲师</span>
            </div>

            <div class="jytd-info-feature">
              <span>授课风格</span>
              <span>风趣幽默,集颜值与才华于一身</span>
            </div>
            <div class="jytd-info-intro">
              <span>讲师简介</span>
              <span>拥有近百所高校授课经历，对高校专业人才培养、
                专业核心课程设计有深入的理解；曾参与教育类、社交
                类和AppFrameV5.5框架项目的设计与开发；擅长Java、
                设计模式、计算机网络、MySQL、HTML5、SpringMVC、
                Hibernate、MyBatis、JPA、SpringBoot等技术，
                深受广大学员喜爱。</span>
            </div>
          </div>
        </div>
        -->

        <!--          李帆老师-->
        <!-- 
        <div class="jytd-item">
          <img class="img1" src="~@/assets/page/jytd/Slice 9@2x.png">
          <div class="jytd-img">
            <img class="imgbg" src="~@/assets/page/jytd/Slice 15@2x.png">
            <img class="imgtea" src="~@/assets/page/jytd/teacher/Slice 62@2x.png" />
          </div>
          <div class="jytd-info">
            <div class="jytd-info-name">
              <span :style="teacherName(0).style">李帆</span>
              <span :style="teacherName(0).levelStyle">Java高级讲师</span>
            </div>

            <div class="jytd-info-feature">
              <span>授课风格</span>
              <span>循序渐进,通俗易懂,思维缜密</span>
            </div>
            <div class="jytd-info-intro">
              <span>讲师简介</span>
              <span>曾担任多家大型互联网公司项目经理、技术经理、
                高级软件工程师职位，有丰富的项目研发经验；曾参
                与三大运营商数据监控系统、国家电网一体化平台和
                能耗系统项目的研发工作；擅长JavaSE、JavaWeb、
                HTML、JS、Vue、SSM框架、SpringBoot、
                SpringCloud微服务分布式架构等技术,对JDK、
                SSM底层源码、JUC高并发、JVM等有深入研究。</span>
            </div>
          </div>
        </div> 
      -->

        <!--          周宝鹏老师-->
        <!--
        <div class="jytd-item">
          <img class="img1" src="~@/assets/page/jytd/Slice 9@2x.png">
          <div class="jytd-img">
            <img class="imgbg" src="~@/assets/page/jytd/Slice 15@2x.png">
            <img class="imgtea" src="~@/assets/page/jytd/teacher/Slice 63@2x.png" />
          </div>
          <div class="jytd-info">
            <div class="jytd-info-name">
              <span :style="teacherName(0).style">周宝鹏</span>
              <span :style="teacherName(0).levelStyle">C语言、Java高级讲师</span>
            </div>

            <div class="jytd-info-feature">
              <span>授课风格</span>
              <span>寓教于乐,风趣幽默</span>
            </div>
            <div class="jytd-info-intro">
              <span>讲师简介</span>
              <span>精通企业主流开源框架、数据结构与算法，
                擅长JavaSE、JDK源码解析、JavaWeb、MySQL、
                MyBatis、SpringData、Spring、SpringMVC、
                SpringBoot等技术；有丰富的项目开发与教学经验，
                参与过财务类报销系统、电力电缆的MES系统和SAP
                接口等知名项目的开发；曾获得文都教育年度“优秀讲师”称号。</span>
            </div>
          </div>
        </div>
        -->
        
        <!--          王丰老师-->
        <!-- 
        <div class="jytd-item">
          <img class="img1" src="~@/assets/page/jytd/Slice 9@2x.png">
          <div class="jytd-img">
            <img class="imgbg" src="~@/assets/page/jytd/Slice 15@2x.png">
            <img class="imgtea" src="~@/assets/page/jytd/teacher/Slice 58@2x.png" />
          </div>
          <div class="jytd-info">
            <div class="jytd-info-name">
              <span :style="teacherName(0).style">王丰</span>
              <span :style="teacherName(0).levelStyle">C++、嵌入式高级讲师</span>
            </div>

            <div class="jytd-info-feature">
              <span>授课风格</span>
              <span>风格随和,擅长总结,风趣幽默</span>
            </div>
            <div class="jytd-info-intro">
              <span>讲师简介</span>
              <span>曾参与上海通用汽车智能驾驶项目、华为OLT设备应用开发，
                承担过项目技术负责人和项目经理的角色。主讲C/C++、数据结构、
                算法课程、Linux应用开发、裸机开发等方向，擅长总结与类比，
                课堂内容详细生动。有丰富的面试经验，能帮助学生进行职业规划。</span>
            </div>
          </div>
        </div> 
      -->


      </div>


    </div>
  </div>
</template>

<script>
const teacherList = [];//师资列表
const getStyle = [
  {
    style: { fontWeight: 'bold', color: '#1C70FF', fontSize: '22px', textAlign: 'justify', textAlignLast: 'justify', width: '76px', marginRight: '30px' },
    levelStyle: { fontFamily: '黑体', color: '#333' }
  },

  {
    style: { fontWeight: 'bold' },
    levelStyle: { fontFamily: '黑体', marginLeft: '1px' }
  }
]

const getTeacherList = async function () {
  const res = await this.$httpGet('front/teacher/list_jytd')
  this.teacherList = res.data.map(t => {
    const arr = t.feature && t.feature.split(",")
    return { ...t, feature: (arr instanceof Array ? arr : [arr]) }
  })
  //console.log(this.teacherList)
}
const teacherName = function (idx) {
  let len = this.teacherList[idx] && this.teacherList[idx].name.length;
  //console.log(len)
  if (len == 3) {
    return getStyle[0]
  } else {
    return getStyle[1]
  }
}
export default {
  data() {
    return {
      teacherList, getStyle
    }
  },
  created() {
    this.getTeacherList()
  },
  methods: {
    getTeacherList, teacherName
  }
}
</script>

<style scoped>
@import url('./jytd.css');
</style>