
<template>
  <div class="about">
    <!-- 广告图 -->
    <div class="adv">
      <img src="~@/assets/page/about/Slice 2@2x.png" />
    </div>

    <!-- 文都教育 -->
    <div class="wdjy">
      <div class="left">
        <h2>文都教育</h2>
        <img style="width:610px;" :src="`${$baseUrl}/about/Slice 4@2x.png`" >
        <div class="introduce">
          世纪文都教育科技集团是专注于大学生升学、就业和职业技能提升的专业化教育集团。公司总部位于北京市海淀区中关村高科技园区，业务覆盖大学教育、职业教育和校企合作等教育培训领域。文都教育以精彩课程、优质资料、专业服务、前沿技术、科学管理及全方位品建设，成为大学生领域的专业教育品牌。基于移动互联网、人工智能、大数据、云计算等技术的应用，文都教育打造以文都教育大数据、文都智能学习系统、文都教育新生态为核心的文都智慧教育。
        </div>
      </div>       
      <div class="wdjy-img">
        <img :src="`${$baseUrl}/about/Slice 3@2x.png`" />
      </div>
      
    </div>


    <!-- 文都智链 -->
    <div class="wdzl">
      <div class="wdzl-img">
        <img :src="`${$baseUrl}/about/Slice 6@2x.png`" />
      </div>

      <div class="right">
        <h2>
          <span>文都智链</span>
          <div><img :src="`${$baseUrl}/about/Slice 5@2x.png`" /></div>       
          
        </h2>
        
      
      
        <div class="part1">
          陕西文都智链计算机科技有限公司，简称文都智链（文都IT)隶属文都集团陕西直营分部。业务涵盖：IT软件开发、计算机软硬件服务、具有技术研发团队、高端IT技术人才培养服务、长期为文都集团内部合作大客户，提供在线平台、软件开发、大数据、物联网、凝聚IT培训行业经验、高端企业人才定制。
        </div>
        <div class="part2">
          公司拥有强大的研发团队，致力为各合作企业解决项目研发问题，互联网＋、一带一路、国企改革、智慧城市等新时代机遇下，公司凭借先进云计算平台、创新融合物联网、移动互联、大数据、人工智能、区块链等新兴技术，精研软硬一体整合应用技术，为能源、航天航空、高端装备、轨道交通等大型集团企业提供全面的行业解决方案和服务。
        </div>

      </div>

      
    </div>

    <!-- 企业荣誉 start -->
    <index-honor title-sub="教培行业影响力品牌、荣获国内教培领域多项大奖"></index-honor>
    <!-- 企业荣誉 end -->

    <!-- 业务规模 -->

      <div class="ywgm">
        <div class="index-honor-title">
          <div class="title_main">业务规模</div>
          <div class="title_sub">BUSINISS SCOPE</div>
        </div>
        <div style="height: 50px">
        </div>
        <div class="wdzl-img">
          <img :src="`${$baseUrl}/about/map@2x.png`"/>
        </div>
        <div class="wdzl-img-smile">
          <img :src="`${$baseUrl}/about/Slice 8@2x.png`" />
        </div>

        <div class="about-map-text">

          <div class="ji">品牌历史</div>
          <div class="ou">专注教育近20年</div>
          <div class="ji">校区分布</div>
          <div class="ou">覆盖全国近30个省份</div>
          <div class="ji">知识产权</div>
          <div class="ou">累计申请知识产权1100多件</div>

          <div class="desc">业务覆盖区域截至2022年2月，其中四川、贵州、广西、福建为文都合作商覆盖区域。</div>

        </div>
        <div style="height: 30px"></div>
      </div>




    <div style="text-align: left;background-color:#F8F7FC;">
      <x-jybz></x-jybz>
    </div>



  </div>
</template>

<script>
import IndexHonor from '@/views/index/components/IndexHonor.vue'
import XJybz from "@/components/XJybz.vue"
export default {
  components: {
   IndexHonor, // 企业荣誉
   XJybz       // 就业保障
  },
  data(){
    return {
      
    };
  },
  created(){
    
     
  },
  methods:{

  },


}
</script>

<style scoped>
@import url('./about.css');
</style>