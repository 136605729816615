var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"x-arrow-tab"},_vm._l((_vm.tabList),function(tab,idx){return _c('div',{staticClass:"x-arrow-tab-item-package"},[(idx==0)?_c('img',{staticClass:"imgicon",style:({
          display:
          _vm.value==idx
          ? 'none':'block'
      }),attrs:{"src":require("@/assets/page/jybz/icon/icon1.png")}}):_vm._e(),(idx==1)?_c('img',{staticClass:"imgicon",style:({
          display:
          _vm.value==idx
          ? 'none':'block'
      }),attrs:{"src":require("@/assets/page/jybz/icon/icon3.png")}}):_vm._e(),(idx==2)?_c('img',{staticClass:"imgicon",style:({
           display:
          _vm.value==idx
          ? 'none':'block'
      }),attrs:{"src":require("@/assets/page/jybz/icon/icon5.png")}}):_vm._e(),(idx==3)?_c('img',{staticClass:"imgicon",style:({
          display:
          _vm.value==idx
          ? 'none':'block'
      }),attrs:{"src":require("@/assets/page/jybz/icon/icon7.png")}}):_vm._e(),(idx==0 && _vm.value==idx)?_c('img',{staticClass:"imgicon",attrs:{"src":require("@/assets/page/jybz/icon/icon2.png")}}):_vm._e(),(idx==1 && _vm.value==idx)?_c('img',{staticClass:"imgicon",attrs:{"src":require("@/assets/page/jybz/icon/icon4.png")}}):_vm._e(),(idx==2 && _vm.value==idx)?_c('img',{staticClass:"imgicon",attrs:{"src":require("@/assets/page/jybz/icon/icon6.png")}}):_vm._e(),(idx==3 && _vm.value==idx)?_c('img',{staticClass:"imgicon",attrs:{"src":require("@/assets/page/jybz/icon/icon8.png")}}):_vm._e(),_c('div',{key:("a-" + (tab.value)),staticClass:"x-arrow-tab-item",style:({
          background:
            _vm.value == idx
              ? ("linear-gradient(to right," + _vm.activeColorStart + "," + _vm.activeColorEnd + ")")
              : _vm.color,
          // backgroundImage:
          //  value == 0
          //     ? `url(~@/assets/page/jybz/icon/icon1.png)`
          //     : `url(~@/assets/page/jybz/icon/Slice 37@2x.png)`,
          color: _vm.value == idx ? _vm.activeTextColor : _vm.textColor,
        }),domProps:{"textContent":_vm._s(tab.label)},on:{"mouseover":function($event){return _vm.selectItem({ tab: tab, idx: idx })}}})])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }