<script>
import { ref, provide, nextTick } from "@vue/composition-api";
import XNav from "@/components/XNav.vue";
import XBottom from "@/components/XBottom.vue";
export default {
 
 components:{
   XNav,XBottom
 },

  setup() {
    const routerViewVisible = ref(true);
    const reload = () => {
      routerViewVisible.value = false;
      nextTick(() => {
        routerViewVisible.value = true;
      });
    };
    provide("reload", reload);
    return {
      routerViewVisible,
    };
  },
};
</script>
<template>
<div class="App" :style="{ paddingTop: $store.state.nav.paddingTop }" >
  <x-nav></x-nav>
  <!-- <keep-alive>
    <router-view v-if="$route.meta.keepAlive" />
  </keep-alive>
  <router-view v-if="!$route.meta.keepAlive" /> -->
  <div style="flex:1;" v-if="routerViewVisible" >
    <router-view ></router-view>
  </div>
  <x-bottom></x-bottom>
  
</div>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.App{
  display:flex;flex-direction: column;min-height:800px;min-width:1440px;width:100%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}


</style>
