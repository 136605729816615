<template>
  <div class="index-honor" >
    <!-- 标题 -->
    <div class="index-honor-title">
      <div class="title_main">企业荣誉</div>
      <div class="title_sub" v-text="titleSub" ></div>
    </div>

    <!-- 轮播图 -->
    <vue-seamless-scroll
      :data="honorList"
      :class-option="optionLeft"
      class="seamless"
    >
      <div class="honor">
        <div
          class="honor-info"
          v-for="honor in honorList"
          :key="honor.id"
        >
          <img :src="`${$apiBaseUrl}${honor.imagePath}`" />
        </div>
      </div>
    </vue-seamless-scroll>
  </div>
</template>
<script>
import VueSeamlessScroll from "vue-seamless-scroll";

const honorList = []; // 荣誉照片集合
const optionLeft = {
  step: 1, // 数值越大速度滚动越快
  limitMoveNum: 2, // honorList.length / 2 - 1, // 开始无缝滚动的数据量 this.dataList.length
  hoverStop: true, // 是否开启鼠标悬停stop
  direction: 2, // 0向下 1向上 2向左 3向右
  openWatch: true, // 开启数据实时监控刷新dom
  singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
  singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
  waitTime: 0, // 单步运动停止的时间(默认值1000ms)
};
// 获取所有荣誉照片
const getHonorList = async function () {
  const result = await this.$httpGet("/front/honor/list");
  this.honorList = result.data;
};

export default {
  props: {
    titleSub: {
      type: String,
      default: "文都教育凭借过硬的品牌实力、优质的教学服务获得了媒体及社会各界的青睐",
    },
    
  },
  components:{
    VueSeamlessScroll
  },
  data() {
    return {
      honorList,
      optionLeft,
    };
  },
  created() {
    this.getHonorList();
  },
  mounted() {

  },
  methods: {
    getHonorList
  },
};
</script>

<style scoped >

</style>

<style>
@import url(./IndexHonor.css);
</style>